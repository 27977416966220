import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import React, { Fragment } from "react"
import { Image } from "semantic-ui-react"
import Slider from "react-slick"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      if (node.data.target.fields) {
        const { title, description, file } = node.data.target.fields

        if (title) {
        }

        const mimeType = file["en-US"].contentType
        const mimeGroup = mimeType.split("/")[0]

        switch (mimeGroup) {
          case "image":
            return (
              <img
                title={title ? title["en-US"] : null}
                alt={description ? description["en-US"] : null}
                src={file["en-US"].url}
              />
            )
          case "application":
            return (
              <a
                alt={description ? description["en-US"] : null}
                href={file["en-US"].url}
              >
                {title ? title["en-US"] : file["en-US"].details.fileName}
              </a>
            )
          default:
            return (
              <span style={{ backgroundColor: "red", color: "white" }}>
                {mimeType} embedded asset
              </span>
            )
        }
      }
    },
  },
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,

  // customize dot css here!
  // appendDots: dots => (
  //   <div
  //     style={{
  //       backgroundColor: "#ddd",
  //       borderRadius: "10px",
  //       padding: "10px"
  //     }}
  //   >
  //     <ul style={{ margin: "0px" }}> {dots} </ul>
  //   </div>
  // ),
  // customPaging: i => (
  //   <div
  //     style={{
  //       width: "30px",
  //       color: "blue",
  //       border: "1px blue solid"
  //     }}
  //   >
  //     {i + 1}
  //   </div>
  // )
}

const EventMain = ({ event }) => {
  return (
    <Fragment>
      <Slider {...settings}>
        {event.eventBannerImages.map(image => (
          <Image src={image.fluid.src} key={image.id} />
        ))}
      </Slider>
      <div className="event-main">
        {documentToReactComponents(event.eventParagraph.json, options)}
      </div>
    </Fragment>
  )
}

export default EventMain
