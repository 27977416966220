import React from "react"
import { Segment, Grid } from "semantic-ui-react"
import HeaderComponent from "../common/header"

const EventBanner = ({ event }) => {
  return (
    <Segment
      inverted
      textAlign="center"
      vertical
      style={{
        background: "#F2F2F2",
        height: "450px",
      }}
      className="product-banner"
    >
      <HeaderComponent page="events"  blackLogo={true}/>

      <Grid divided="vertically" style={{width:'100%'}}>
        <Grid.Row columns={2}>
          <Grid.Column className="product-banner-left">
            <h1>{event.eventTitle}</h1>
            <p>{event.eventTime}</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default EventBanner
