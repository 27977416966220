import React from "react"
import { graphql } from "gatsby";
import EventBanner from "./eventBanner"
import EventMain from "./eventMain";
import Footer from "../common/footer"
import "semantic-ui-css/semantic.min.css"
import "../../styles/index.css"

const EventDetail = ({ data }) => {
  const event = data.contentfulEvents

  return (
    <div style={{background:'#F2F2F2'}}>
      <EventBanner event={event}/>
      <EventMain event={event}/>
      <Footer />
    </div>
  )
}
export default EventDetail

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      slug
      eventTitle
      eventTime
      eventDescription {
        eventDescription
      }
      eventCoverImage {
        fluid (maxWidth: 4000,quality:100){
          src
        }
      }
      eventBannerImages {
        id
        fluid (maxWidth: 4000,quality:100){
          src
        }
      }
      eventParagraph {
        json
      }

    }
  }
`;